@import "reset"
@import "default-classes"
@import "fonts"

.childent-app
  display: flex
  flex-direction: column
  justify-content: space-between
  min-height: 100vh

::-webkit-scrollbar
  width: 10px
::-webkit-scrollbar-track
  background: #fff
::-webkit-scrollbar-thumb
  background: rgb(31, 31, 31, .8)
::-webkit-scrollbar-thumb:hover
  background: rgb(31, 31, 31, .95)