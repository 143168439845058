.price-section
  margin-top: -50px
  padding: 65px 0 30px
  background-image: url('../../img/price-background.jpg')
  background-repeat: no-repeat
  background-size: cover!important
  background-position: center
  @media (min-width: 321px) and (max-width: 767px)
    margin-top: -75px
    padding-top: 75px
  @media (min-width: 768px)
    margin-top: -100px
    padding: 130px 0 50px
.price-caption
  padding: 0 15px
  margin-top: 15px
  margin-bottom: 20px
  font: 400 38px Oswald
  color: #fff
  text-align: center
  text-transform: uppercase
  @media (min-width: 768px)
    padding: 0 15px
    margin-top: 30px
    margin-bottom: 45px
.price-list
  display: flex
  flex-flow: wrap
  justify-content: center
  margin: 30px 0
  @media (min-width: 768px)
    margin: 45px 0
.price-item
  padding: 0 15px 20px
  width: 100%
  @media (min-width: 560px) and (max-width: 767px)
    width: 290px
    max-width: 50%
  @media (min-width: 768px)
    padding-bottom: 30px
    width: 290px
    max-width: 33%
.price-item-name
  margin-bottom: 10px
  font: 400 16px/24px 'Open Sans'
  color: #fff
  text-align: center
.price-item-cost
  font: 700 18px Oswald
  color: #fff
  text-align: center
.old-price
  text-decoration: line-through
  text-decoration-thickness: 2px