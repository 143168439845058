html
  -webkit-text-size-adjust: 100%

*
  margin: 0
  padding: 0
  box-sizing: border-box

ul, ol, li
  list-style: none
a
  text-decoration: none

a, button, input, select
  &:hover, &:focus, &:visited
    outline: none

input:hover,
input:active,
input:focus,
select:hover,
select:active,
select:focus
  outline: none
  -webkit-appearance: none
  border-radius: 0

button::-moz-focus-inner
  border: 0