.team-section
  background-color: #fff
  padding: 15px 0 30px
  @media (min-width: 768px)
    padding: 30px 0 50px
  .team-list
    display: flex
    flex-direction: column
    align-items: center
    @media (min-width: 768px)
      flex-direction: row
      flex-wrap: wrap
      justify-content: center
    @media (min-width: 1170px)
      justify-content: space-between
  .team-item
    display: flex
    flex-direction: column
    padding: 20px 0
    max-width: 360px
    @media (min-width: 768px)
      padding: 10px
    @media (min-width: 768px) and (max-width: 911px)
      width: 50%
    @media (min-width: 912px)
      width: 33%
      padding: 15px
    @media (min-width: 1170px)
      width: 360px
      padding: 20px 0
  .team-item-photo
    margin-bottom: 15px
  .team-item-name
    font: 400 20px Oswald
    text-align: center
    text-transform: uppercase