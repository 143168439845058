.contact-page
  padding: 30px 15px
  @media (min-width: 1170px)
    padding: 50px 15px
  .address-list
    @media (min-width: 768px)
      padding-right: 15px
  .contact-info
    display: flex
    flex-direction: column
    @media (min-width: 768px)
      flex-direction: row
      justify-content: space-between
#map-wrapper
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 500px
  @media (min-width: 768px)
    width: 65%
    height: 550px
  iframe
    width: 100%
    height: 100%