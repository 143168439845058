.layout-wrap
  display: flex
  flex-direction: column
  flex-grow: 1
  background-color: rgba(31, 31, 31, 0.8)
  padding-top: 50px
  @media (min-width: 321px) and (max-width: 767px)
    padding-top: 75px
  @media (min-width: 768px)
    padding-top: 100px
.container
  width: 100%
  max-width: 1170px
  margin: auto
  padding-left: 15px
  padding-right: 15px
.img-fluid
  display: block
  max-width: 100%
  height: auto
.lg-caption
  font: 400 40px/1.4 Oswald
  color: rgba(31,31,31,1)
  display: block
  text-align: center
  padding: 15px
  text-transform: uppercase
  @media (min-width: 768px)
    padding: 30px 15px
.sm-caption
  font: 600 22px "Open Sans"
.regular-text
  font: 400 17px "Open Sans"
.text-uppercase
  text-transform: uppercase