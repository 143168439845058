.welcome
  display: flex
  justify-content: center
  align-items: center
  flex-grow: 1
  background-repeat: no-repeat
  background-size: cover!important
  background-position: center
  margin-top: -50px
  padding-top: 50px
  min-height: 72vw
  @media (min-width: 321px) and (max-width: 767px)
    margin-top: -75px
    padding-top: 75px
  @media (min-width: 768px)
    margin-top: -100px
    padding-top: 100px
    min-height: 45vw
.welcome-home
  background-image: url('../../img/welcome-background.jpg')
  @media (min-width: 768px)
    background-position-y: 15%
.welcome-title
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  font: 400 30px/1.2 Oswald
  color: #fff
  @media (min-width: 360px) and (max-width: 768px)
    font-size: 36px
  @media (min-width: 768px)
    font-size: 48px