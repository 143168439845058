.address-list
  display: flex
  flex-direction: column
.address-item
  min-width: 210px
  padding-bottom: 10px
  margin-bottom: 15px
.address-caption
  color: #fff
  margin-bottom: 15px
.schedule-info
  font: 400 18px "Open Sans"
  color: #fff
.address-link
  display: inline-block
  font: 400 17px "Open Sans"
  color: #fff
  transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1)
  &:hover
    color: #606060
.schedule-info, .address-link
  position: relative
  padding: 8px 7px 8px 50px
  margin-left: -10px
  margin-bottom: 8px
  color: #fff
  &:before
    content: ''
    background-repeat: no-repeat
    background-position-y: center
    position: absolute
    width: 38px
    top: -5px
    left: 10px
    bottom: -5px
.schedule-info
  padding-left: 52px
  &:before
    background-image: url("../../icons/icon-clock-32.png")
    background-size: 36px
.address-link.gmap-link
  &:before
    background-image: url("../../icons/icon-google-maps-old-32.png")
    background-size: 38px
    background-position-x: -3px
.number-link
  &:before
    background-image: url("../../icons/icon-phone-32.png")
.instagram-link
  &:before
    background-image: url("../../icons/icon-instagram-32.png")
  .social-media-profile
    color: #6e59bc
.telegram-link
  &:before
    background-image: url("../../icons/icon-telegram-32.png")
  .social-media-profile
    color: #039be5
.address-link.viber-link
  &:before
    background-image: url("../../icons/icon-viber-32.png")
    background-size: 38px
    background-position: 0
    left: 7px
  .social-media-profile
    color: #7e57c2
.email-link
  &:before
    background-image: url("../../icons/icon-email-32.png")
.social-media-profile
  display: none

@media (min-width: 992px)
  .address-link
    &:before
      opacity: .8
      transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1)
    &:hover
      &:before
        opacity: 1
      .social-media-profile
        opacity: 1
  .social-media-profile
    display: inline
    opacity: 0
    transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1)