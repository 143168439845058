.header
  display: block
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 100
  max-width: 100vw!important
  transition: all .5s ease
  @media (min-width: 768px)
    box-shadow: none
    //transition: box-shadow 125ms cubic-bezier(0.4,0,0.2,1)
    &:hover
      background-color: rgb(31, 31, 31, .6)
      box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%)
  &.scrolled
    background-color: #1f1f1f
    @media (min-width: 768px)
      .nav-link.active
        background-color: #fff
        color: #1f1f1f
  .container
    display: flex
    align-items: center
    justify-content: space-between
  .logo-link
    display: block
    max-width: 50px
    @media (min-width: 321px) and (max-width: 767px)
      max-width: 75px
    @media (min-width: 768px)
      max-width: 100px
  .logo
    display: block
    width: 100%
  .nav
    position: fixed
    top: 0
    right: 0
    display: flex
    justify-content: flex-end
    width: 100%
    z-index: 200
    &.opened
      height: 100vh
      @media (min-width: 768px)
        height: auto
      .nav-list
        right: 0
      .dark-background
        &:after
          opacity: .6
    @media (min-width: 768px)
      position: relative
      height: auto
    @media (min-width: 768px)
      &:after
        display: none!important
  .dark-background
    z-index: 190
    @media (min-width: 768px)
      display: none
    &:after
      content: ''
      display: block
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      background-color: #000
      opacity: 0
      transition: opacity .5s
  .nav-list
    position: fixed
    top: 0
    right: -250px
    width: 250px
    height: 100vh
    display: flex
    flex-direction: column
    background-color: #fff
    z-index: 300
    padding-top: 50px
    padding-left: 15px
    transition: right 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s
    @media (min-width: 321px) and (max-width: 767px)
      padding-top: 75px
    @media (min-width: 768px)
      position: relative
      right: 0
      height: auto
      flex-direction: row
      width: auto
      padding-top: 0
      padding-left: 0
      background-color: transparent
  .nav-item
    display: flex
    margin-top: 15px
    @media (min-width: 321px) and (min-width: 767px)
      margin-top: 20px
    @media (min-width: 768px)
      margin-top: 0
  .nav-link
    display: block
    font: 600 16px/1.88 'Open Sans'
    color: rgba(31,31,31,1)
    white-space: nowrap
    padding: 0 10px
    margin: 0 6px
    border-radius: 25px
    &:hover
      opacity: .7
    &:last-child
      margin-right: 0
    &.active
      background-color: rgba(31,31,31,1)
      color: #fff
    @media (min-width: 768px)
      padding: 3px 15px
      font-weight: 400
      color: #fff
      transition: all .5s
  .btn-toggle-menu
    display: flex
    position: relative
    flex-direction: column
    justify-content: center
    align-items: center
    width: 55px
    height: 55px
    padding: 5px
    cursor: pointer
    outline: none
    overflow: hidden
    z-index: 400
    @media (min-width: 321px) and (max-width: 767px)
      width: 65px
      height: 65px
      padding: 10px
    @media (min-width: 768px)
      display: none
    .icon-bar
      color: #fff
      transition: all .5s cubic-bezier(0.4, 0, 0.2, 1)
    &.active
      .icon-bar
        color: #000
        &:first-child
          transform: rotate(45deg) translate(0px,5px)
        &:nth-child(2)
          transform: rotate(135deg)
        &:last-child
          transform: rotate(135deg) translate(0px,-5px)